/**
 * This file contains functions for communication with the node-api server.
 */
import Event, { ServerEventDataType } from "@/shared/data/event";
import axios from "axios";
import Tag, { ServerTagDataType } from "@/shared/data/tag";
import Address, { ServerAddressDataType } from "@/shared/data/address";
import Rubric, { ServerRubricDataType } from "@/shared/data/rubric";
import SignupData from "@/shared/data/signup";
import Person from "@/shared/data/person";
import {
  NumberValueListItem,
  ServerValueListItemDataType,
  StringValueListItem,
} from "@/shared/data/value_list";
import { DateTime } from "luxon";
import { formatDate, parseTimestamp } from "@/shared/lib/datetime";
import Community, { ServerCommunityDataType } from "@/shared/data/community";
import FrontendLogger from "@/frontend/lib/logger";
import FrontendSettings from "@/frontend/settings/settings";
import RecordingEvent, {
  ServerRecordingEventDataType,
} from "@/shared/data/recording_event";
import {
  AdvertCampaign,
  AdvertContent,
  Order,
  OrderItem,
  ServerAdvertCampaignDataType,
  ServerAdvertOrderDataType,
  ServerOrderItemDataType,
} from "@/shared/data/advert";
import RecordingArticle from "@/shared/data/recording_article";
import { ArticleDefinition } from "@/shared/data/article_definition";
import { EventListAdminDisplayType } from "@/components/quokka_layout/admin/QuokkaEventListAdminMyEventList.vue";

/**
 * Tries to get a specific event from the api server.
 *
 * @param id The id to get
 * @returns the Event on resolve and an error on reject, if something failed. Could be an HTTP-Error or TypeError.
 */
export function getEvent(id: number): Promise<Event> {
  return new Promise<Event>((resolve, reject) => {
    axios
      .get(FrontendSettings.host + "api/v1/events/" + id)
      .then((response) => {
        try {
          resolve(Event.fromServer(response.data.data));
        } catch (e) {
          FrontendLogger.error({
            message: "Failed to parse Event-object.",
            data: e,
            scope: "api",
          });
          reject(e);
        }
      })
      .catch((e) => {
        reject(e);
      });
  });
}

/**
 * Tries to get events from the api server.
 *
 * @param parameter Parameters for the request
 * @returns the list of Events on resolve and an error on reject, if something failed. Could be an HTTP-Error or TypeError.
 */
export function getEvents(parameter: {
  per_page?: number;
  page?: number;
  query?: string;
  start_date?: DateTime;
  end_date?: DateTime;
  communities?: Community[];
  range?: number;
  districts?: Community[];
  rubric?: Rubric;
  tags?: Tag;
}): Promise<GetEventsReturnType> {
  return new Promise<GetEventsReturnType>((resolve, reject) => {
    const requestParams: Record<string, unknown> = Object.assign({}, parameter);

    if (parameter.start_date !== undefined)
      requestParams.start_date = formatDate(parameter.start_date, "yyyy-MM-dd");
    if (parameter.end_date !== undefined)
      requestParams.end_date = formatDate(parameter.end_date, "yyyy-MM-dd");
    if (parameter.communities !== undefined) {
      const reqCommunities: number[] = [];
      const reqDistricts: number[] = [];

      for (const com of parameter.communities) {
        if (com.district_id !== null && com.district_id > 0)
          reqDistricts.push(com.district_id);
        if (com.community_id !== null && com.community_id > 0)
          reqCommunities.push(com.community_id);
      }

      if (reqCommunities.length > 0)
        requestParams.communities = reqCommunities.join(",");
      if (reqDistricts.length > 0)
        requestParams.districts = reqDistricts.join(",");
    }
    if (parameter.rubric) requestParams.rubrics = parameter.rubric.rubric_id;
    if (parameter.tags) requestParams.tags = parameter.tags.tag_id;
    if (parameter.range) requestParams.range = parameter.range;

    axios
      .get(FrontendSettings.host + "api/v1/events", {
        params: requestParams,
      })
      .then((response) => {
        const events: Event[] = [];
        response.data.data.results.forEach((data: ServerEventDataType) => {
          try {
            events.push(Event.fromServer(data));
          } catch (e) {
            FrontendLogger.error({
              message: "Failed to parse Event-object. Ignoring it.",
              data: e,
              scope: "api",
            });
          }
        });
        resolve({
          events: events,
          pagesCountOverall: response.data.data.pages_count_overall,
          resultsCountOverall: response.data.data.results_count_overall,
        });
      })
      .catch((e) => {
        reject(e);
      });
  });
}

export type GetEventsReturnType = {
  events: Event[];
  pagesCountOverall: number;
  resultsCountOverall: number;
};

/**
 * Tries to get a specific RecordingEvent from the api server.
 *
 * @param id The id to get
 * @returns the RecordingEvent on resolve and an error on reject, if something failed. Could be an HTTP-Error or TypeError.
 */
export function getRecordingEvent(id: number): Promise<RecordingEvent> {
  return new Promise<RecordingEvent>((resolve, reject) => {
    axios
      .get(FrontendSettings.host + "api/v1/eventsRecording/" + id)
      .then((response) => {
        try {
          resolve(RecordingEvent.fromServer(response.data.data));
        } catch (e) {
          FrontendLogger.error({
            message: "Failed to parse RecordingEvent-object.",
            data: e,
            scope: "api",
          });
          reject(e);
        }
      })
      .catch((e) => {
        reject(e);
      });
  });
}

/**
 * Tries to get recording events from the api server for the logged-in person.
 *
 * @param parameter Parameters for the request
 * @returns the list of RecordingEvents on resolve and an error on reject, if something failed. Could be an HTTP-Error or TypeError.
 */
export function getRecordingEvents(parameter: {
  per_page?: number;
  page?: number;
  query?: string;
  start_date?: DateTime;
  end_date?: DateTime;
  communities?: Community[];
  districts?: Community[];
  rubric?: Rubric;
  tags?: Tag;
  event_list_display_type?: EventListAdminDisplayType;
}): Promise<GetRecordingEventsReturnType> {
  return new Promise<GetRecordingEventsReturnType>((resolve, reject) => {
    const requestParams: Record<string, unknown> = {};
    if (parameter.per_page !== undefined)
      requestParams.per_page = parameter.per_page;
    if (parameter.page !== undefined) requestParams.page = parameter.page;
    if (parameter.query !== undefined) requestParams.query = parameter.query;

    if (parameter.start_date !== undefined)
      requestParams.start_date = formatDate(parameter.start_date, "yyyy-MM-dd");
    if (parameter.end_date !== undefined)
      requestParams.end_date = formatDate(parameter.end_date, "yyyy-MM-dd");
    if (parameter.communities !== undefined) {
      const requestCommunities: number[] = [];
      const requestDistricts: number[] = [];
      for (const com of parameter.communities) {
        if (com.district_id !== null) {
          requestDistricts.push(com.district_id);
        } else {
          requestCommunities.push(com.community_id);
        }
      }
      if (requestCommunities.length > 0) {
        requestParams.communities = requestCommunities.join(",");
      }
      if (requestDistricts.length > 0) {
        requestParams.districts = requestDistricts.join(",");
      }
    }
    if (parameter.rubric) requestParams.rubrics = parameter.rubric.rubric_id;
    if (parameter.tags) requestParams.tags = parameter.tags.tag_id;
    if (parameter.event_list_display_type)
      requestParams.event_list_display_type = parameter.event_list_display_type;

    axios
      .get(FrontendSettings.host + "api/v1/eventsRecording", {
        params: requestParams,
      })
      .then((response) => {
        const events: RecordingEvent[] = [];
        response.data.data.results.forEach(
          (data: ServerRecordingEventDataType) => {
            try {
              events.push(RecordingEvent.fromServer(data));
            } catch (e) {
              FrontendLogger.error({
                message: "Failed to parse RecordingEvent-object. Ignoring it.",
                data: e,
                scope: "api",
              });
            }
          }
        );
        resolve({
          recordingEvents: events,
          pagesCountOverall: response.data.data.pages_count_overall,
          resultsCountOverall: response.data.data.results_count_overall,
          page: response.data.data.page,
        });
      })
      .catch((e) => {
        reject(e);
      });
  });
}

export type GetRecordingEventsReturnType = {
  recordingEvents: RecordingEvent[];
  pagesCountOverall: number;
  resultsCountOverall: number;
  page: number;
};

/**
 * Tries to create the given {@link RecordingEvent} via the api.
 *
 * @param event
 *
 * @return RecordingEvent the created {@link RecordingEvent}
 */
export function createRecordingEvent(
  event: RecordingEvent
): Promise<RecordingEvent> {
  // TODO: Anderer Type für das event, für z.b. location und promoter

  return new Promise<RecordingEvent>((resolve, reject) => {
    axios
      .post(FrontendSettings.host + "api/v1/eventsRecording", event)
      .then((response) => {
        try {
          resolve(RecordingEvent.fromServer(response.data.data));
        } catch (e) {
          FrontendLogger.error({
            message: "Failed to parse RecordingEvent-object after creation.",
            data: e,
            scope: "api",
          });
          reject(e);
        }
      })
      .catch((e) => {
        reject(e);
      });
  });
}

/**
 * Tries to update the given {@link RecordingEvent} via the api.
 *
 * @param event
 *
 * @return RecordingEvent the updated {@link RecordingEvent}
 */
export function updateRecordingEvent(
  event: RecordingEvent
): Promise<RecordingEvent> {
  return new Promise<RecordingEvent>((resolve, reject) => {
    axios
      .put(
        FrontendSettings.host + "api/v1/eventsRecording/" + event.event_id,
        event
      )
      .then((response) => {
        try {
          resolve(RecordingEvent.fromServer(response.data.data));
        } catch (e) {
          FrontendLogger.error({
            message: "Failed to parse RecordingEvent-object after update.",
            data: e,
            scope: "api",
          });
          reject(e);
        }
      })
      .catch((e) => {
        reject(e);
      });
  });
}
export function deleteRecordingEvent(event_id: number): Promise<void> {
  return new Promise<void>((resolve, reject) => {
    axios
      .delete(FrontendSettings.host + "api/v1/eventsRecording/" + event_id)
      .then(() => {
        try {
          resolve();
        } catch (e) {
          FrontendLogger.error({
            message: "Failed to parse RecordingEvent-object after delete.",
            data: e,
            scope: "api",
          });
          reject(e);
        }
      })
      .catch((e) => {
        reject(e);
      });
  });
}

export function getAdvertCampaigns(): Promise<AdvertCampaign[]> {
  return new Promise<AdvertCampaign[]>((resolve, reject) => {
    axios
      .get(FrontendSettings.host + "api/v1/adverts")
      .then((response) => {
        const advertCampaigns: AdvertCampaign[] = [];
        response.data.data.results.forEach(
          (data: ServerAdvertCampaignDataType) => {
            try {
              advertCampaigns.push(AdvertCampaign.fromServer(data));
            } catch (e) {
              FrontendLogger.error({
                message: "Failed to parse AdvertCampaign-Object.",
                data: e,
                scope: "api",
              });
            }
          }
        );
        resolve(advertCampaigns);
      })
      .catch((e) => {
        reject(e);
      });
  });
}

/**
 * Available media configurations types
 */
export enum mediaConfData {
  event = "event",
  advert = "advert",
  article = "article",
}

/**
 * Loads data from media_config table.
 */
export function getMediaConfData(parameter: {
  type: mediaConfData;
}): Promise<getMediaConfReturnData> {
  return new Promise<getMediaConfReturnData>((resolve, reject) => {
    axios
      .get(FrontendSettings.host + "api/v1/mediaConfig/", {
        params: parameter,
      })
      .then((response) => {
        resolve({
          mediaConfigData: response.data.data["mediaConfigData"],
        });
      })
      .catch((e) => {
        reject(e);
      });
  });
}

export type getMediaConfReturnData = {
  mediaConfigData: {
    [key: string]: { [key: string]: { [key: string]: unknown } };
  };
  /*additionalImageInformationFields: getMediaConfReturnDataAdditionalImageTexts;
    uploadImgPxDpi: getMediaConfReturnDataUploadImgPxDpi;
    uploadSize: getMediaConfReturnDataUploadSize;
    maxUploadNumb: getMediaConfReturnDataMaxUploadNumb;*/
};

export type returnMediaConfReturnData = {
  additionalImageInformationFields: getMediaConfReturnDataAdditionalImageTexts;
  uploadImgPxDpi: getMediaConfReturnDataUploadImgPxDpi;
  uploadSize: getMediaConfReturnDataUploadSize;
  maxUploadNumb: getMediaConfReturnDataMaxUploadNumb;
  fileTypes: getMediaConfReturnDataFileTypes;
  mimeTypes: getMediaConfReturnDataMimeTypes;
};

export type getMediaConfReturnDataAdditionalImageTexts = {
  additionalImageInformationFields: { [key: string]: unknown };
};

export type getMediaConfReturnDataUploadImgPxDpi = {
  uploadImgPxDpi: { [key: string]: unknown };
};

export type getMediaConfReturnDataUploadSize = {
  uploadSize: { [key: string]: unknown };
};

export type getMediaConfReturnDataMaxUploadNumb = {
  maxUploadNumb: { [key: string]: unknown };
};

export type getMediaConfReturnDataFileTypes = {
  fileTypes: { [key: string]: unknown };
};

export type getMediaConfReturnDataMimeTypes = {
  mimeTypes: { [key: string]: unknown };
};

/**
 * Loads an uploaded image with its information from an event.
 */
export function loadImageFromEvent(parameter: {
  load: number | string;
  tableName?: string;
  isTableId?: string;
  tmpImgFile?: string;
}): Promise<MediaInfoReturnType> {
  return new Promise<MediaInfoReturnType>((resolve, reject) => {
    axios
      .get(FrontendSettings.host + "api/v1/mediaInfo/", {
        params: parameter,
      })
      .then((response) => {
        resolve({
          media_id: response.data.data["media_id"],
          width: response.data.data["width"],
          height: response.data.data["height"],
          mimetype: response.data.data["mimetype"],
          base64: response.data.data["base64"],
        });
      })
      .catch((e) => {
        reject(e);
      });
  });
}

/**
 * Return values of the mediaInfo endpoint.
 */
export type MediaInfoReturnType = {
  media_id: number;
  width: number;
  height: number;
  mimetype: string;
  base64: string;
};

/**
 * Tries to get amount from date.
 */
export function getAdvertAvailability(parameter: {
  date: string;
  slot_id: number;
  channel_id: number;
}): Promise<AdvertAvailabilityReturnType> {
  return new Promise<AdvertAvailabilityReturnType>((resolve, reject) => {
    axios
      .get(FrontendSettings.host + "api/v1/AdvertsBookingAvailability/", {
        params: parameter,
      })
      .then((response) => {
        resolve({
          availableAmount: response.data.data["availableAmount"],
          optionalChannel: response.data.data["optionalChannel"],
        });
      })
      .catch((e) => {
        reject(e);
      });
  });
}

/**
 * Return values of the advertsBookingAvailability endpoint.
 */
export type AdvertAvailabilityReturnType = {
  availableAmount: number;
  optionalChannel: [];
};

/**
 * builds the temporary order item.
 */
export function buildOrderItems(parameter: {
  date: string;
  action: string;
  slot_id: number;
  channel_id: number;
  order_items: OrderItem[];
}): Promise<BuildOrderReturnType> {
  return new Promise<BuildOrderReturnType>((resolve, reject) => {
    axios
      .get(FrontendSettings.host + "api/v1/AdvertsBookingPriceCalculation/", {
        params: parameter,
      })
      .then((response) => {
        const orderItems: OrderItem[] = [];

        response.data.data["orderItems"].forEach(
          (data: ServerOrderItemDataType) => {
            try {
              orderItems.push(OrderItem.fromServer(data));
            } catch (e) {
              FrontendLogger.error({
                message: "Failed to parse OrderItem-object. Ignoring it.",
                data: e,
                scope: "api",
              });
            }
          }
        );
        resolve({
          orderItems: orderItems,
          totalPrice: response.data.data["totalPrice"],
        });
      })
      .catch((e) => {
        reject(e);
      });
  });
}

/**
 * Return values of the advertsBookingPriceCalculation endpoint.
 */
export type BuildOrderReturnType = {
  orderItems: OrderItem[];
  totalPrice: number;
};

/**
 * Sends the order to the endpoint to be saved.
 */
export function createAdvertOrder(parameter: {
  order: Order;
  orderContent: AdvertContent;
}): Promise<OrderBookingAnswer> {
  return new Promise<OrderBookingAnswer>((resolve, reject) => {
    axios
      .post(FrontendSettings.host + "api/v1/AdvertsBookingOrder/", parameter)
      .then((response) => {
        resolve({
          successFullBooking: response.data.data.successFullBooking,
        });
      })
      .catch((e) => {
        reject(e);
      });
  });
}

/**
 * Return values of the booking endpoint.
 */
export type OrderBookingAnswer = {
  successFullBooking: boolean;
};

/**
 * Tries to get Advert Orders from the api server for the logged-in person.
 *
 * @param parameter Parameters for the request
 * @returns the list of AdvertOrders on resolve and an error on reject, if something failed. Could be an HTTP-Error or TypeError.
 */
export function getAdvertOrders(parameter: {
  per_page?: number;
  page?: number;
}): Promise<GetAdvertOrdersReturnType> {
  return new Promise<GetAdvertOrdersReturnType>((resolve, reject) => {
    axios
      .get(FrontendSettings.host + "api/v1/advertsOrder", {
        params: parameter,
      })
      .then((response) => {
        const orders: Order[] = [];
        response.data.data.results.forEach(
          (data: ServerAdvertOrderDataType) => {
            try {
              orders.push(Order.fromServer(data));
            } catch (e) {
              FrontendLogger.error({
                message: "Failed to parse AdvertOrder-object. Ignoring it.",
                data: e,
                scope: "api",
              });
            }
          }
        );
        resolve({
          advertOrders: orders,
          pagesCountOverall: response.data.data.pages_count_overall,
          resultsCountOverall: response.data.data.results_count_overall,
        });
      })
      .catch((e) => {
        reject(e);
      });
  });
}

export type GetAdvertOrdersReturnType = {
  advertOrders: Order[];
  pagesCountOverall: number;
  resultsCountOverall: number;
};

/**
 * Tries to get addresses from the api server.
 *
 * @returns the list of Addresses on resolve and an error on reject, if something failed. Could be an HTTP-Error or TypeError.
 */
export function getAddresses(parameter: {
  type: "location" | "promoter" | "ticket_agency";
  query?: string;
  query_type?: "name" | "fulltext";
  per_page?: number;
}): Promise<GetAddressReturnType> {
  return new Promise<GetAddressReturnType>((resolve, reject) => {
    axios
      .get(FrontendSettings.host + "api/v1/addresses", {
        params: parameter,
      })
      .then((response) => {
        const addresses: Address[] = [];
        response.data.data.results.forEach((data: ServerAddressDataType) => {
          try {
            addresses.push(Address.fromServer(data));
          } catch (e) {
            FrontendLogger.error({
              message: "Failed to parse Address-Object.",
              data: e,
              scope: "api",
            });
          }
        });
        resolve({
          addresses: addresses,
          pagesCountOverall: response.data.data.pages_count_overall,
          resultsCountOverall: response.data.data.results_count_overall,
        });
      })
      .catch((e) => {
        reject(e);
      });
  });
}

export type GetAddressReturnType = {
  addresses: Address[];
  pagesCountOverall: number;
  resultsCountOverall: number;
};

/**
 * Tries to get communities from the api server.
 *
 * @returns the list of Communities on resolve and an error on reject, if something failed. Could be an HTTP-Error or TypeError.
 */
export function getCommunities(parameter: {
  query?: string;
  per_page?: number;
  sort?: "name_path" | "name";
}): Promise<GetCommunityReturnType> {
  return new Promise<GetCommunityReturnType>((resolve, reject) => {
    const defaultParams = {
      sort: "name_path",
    };

    parameter = Object.assign(defaultParams, parameter);

    axios
      .get(FrontendSettings.host + "api/v1/communities", {
        params: parameter,
      })
      .then((response) => {
        const communities: Community[] = [];
        response.data.data.results.forEach((data: ServerCommunityDataType) => {
          try {
            communities.push(Community.fromServer(data));
          } catch (e) {
            FrontendLogger.error({
              message: "Failed to parse Community-Object.",
              data: e,
              scope: "api",
            });
          }
        });
        resolve({
          communities: communities,
          pagesCountOverall: response.data.data.pages_count_overall,
          resultsCountOverall: response.data.data.results_count_overall,
        });
      })
      .catch((e) => {
        reject(e);
      });
  });
}

export type GetCommunityReturnType = {
  communities: Community[];
  pagesCountOverall: number;
  resultsCountOverall: number;
};

/**
 * Tries to get *all* rubrics from the api server.
 *
 * @returns the list of Rubrics on resolve and an error on reject, if something failed. Could be an HTTP-Error or TypeError.
 */
export function getRubrics(parameter: {
  query?: string;
  tree?: "child" | "master";
  type?: "article" | "all";
}): Promise<Rubric[]> {
  return new Promise<Rubric[]>((resolve, reject) => {
    const requestParams = Object.assign(
      {
        per_page: 1000,
      },
      parameter
    );

    axios
      .get(FrontendSettings.host + "api/v1/rubrics", {
        params: requestParams,
      })
      .then((response) => {
        const rubrics: Rubric[] = [];
        response.data.data.results.forEach((data: ServerRubricDataType) => {
          try {
            rubrics.push(Rubric.fromServer(data));
          } catch (e) {
            FrontendLogger.error({
              message: "Failed to parse Rubric-Object.",
              data: e,
              scope: "api",
            });
          }
        });
        resolve(rubrics);
      })
      .catch((e) => {
        reject(e);
      });
  });
}

/**
 * Tries to get tags from the api server.
 *
 * @returns the list of Tags on resolve and an error on reject, if something failed. Could be an HTTP-Error or TypeError.
 */
export function getTags(parameter: {
  type?: "event" | "address" | "community";
}): Promise<Tag[]> {
  return new Promise<Tag[]>((resolve, reject) => {
    axios
      .get(FrontendSettings.host + "api/v1/tags", { params: parameter })
      .then((response) => {
        const tags: Tag[] = [];
        response.data.data.results.forEach((data: ServerTagDataType) => {
          try {
            tags.push(Tag.fromServer(data));
          } catch (e) {
            FrontendLogger.error({
              message: "Failed to parse Tag-Object.",
              data: e,
              scope: "api",
            });
          }
        });
        resolve(tags);
      })
      .catch((e) => {
        reject(e);
      });
  });
}

/**
 * Tries to get rubrics for the article recording from the api server.
 * @returns the list of Rubrics on resolve and an error on reject, if something failed. Could be an HTTP-Error or TypeError.
 */
export function getArticleDefinition(
  rubric_id: string
): Promise<ArticleDefinition> {
  return new Promise<ArticleDefinition>((resolve, reject) => {
    axios
      .get(FrontendSettings.host + "api/v1/articleDefinition/" + rubric_id)
      .then((response) => {
        resolve(ArticleDefinition.fromServer(response.data.data));
      })
      .catch((e) => {
        reject(e);
      });
  });
}

/**
 * Tries to get a specific RecordingArticle from the api server.
 * @param id The id to get
 * @returns the RecordingArticle on resolve and an error on reject, if something failed. Could be an HTTP-Error or TypeError.
 */
export function getRecordingArticle(id: number): Promise<RecordingArticle> {
  return new Promise<RecordingArticle>((resolve, reject) => {
    axios
      .get(FrontendSettings.host + "api/v1/articlesRecording/" + id)
      .then((response) => {
        RecordingArticle.fromServer(response.data.data)
          .then((article) => {
            resolve(article);
          })
          .catch((e) => {
            FrontendLogger.error({
              message: "Failed to parse RecordingArticle-object.",
              data: e,
              scope: "api",
            });
            reject(e);
          });
      })
      .catch((e) => {
        reject(e);
      });
  });
}

/**
 * Tries to get RecordingArticles from the api server for the logged-in person.
 * @param parameter Parameters for the request
 * @returns the list of RecordingArticles on resolve and an error on reject, if something failed. Could be an HTTP-Error or TypeError.
 */
export function getRecordingArticles(parameter: {
  per_page?: number;
  page?: number;
  query?: string;
  start_date?: DateTime;
  end_date?: DateTime;
  communities?: Community[];
  districts?: Community[];
  rubric?: Rubric;
  addressLocation?: Address;
  addressPromoter?: Address;
}): Promise<GetRecordingArticlesReturnType> {
  return new Promise<GetRecordingArticlesReturnType>((resolve, reject) => {
    const requestParams: Record<string, unknown> = {};
    if (parameter.per_page !== undefined)
      requestParams.per_page = parameter.per_page;
    if (parameter.page !== undefined) requestParams.page = parameter.page;
    if (parameter.query !== undefined) requestParams.query = parameter.query;

    if (parameter.start_date !== undefined)
      requestParams.start_date = formatDate(parameter.start_date, "yyyy-MM-dd");
    if (parameter.end_date !== undefined)
      requestParams.end_date = formatDate(parameter.end_date, "yyyy-MM-dd");
    if (parameter.communities !== undefined) {
      const communities: number[] = [];
      const districts: number[] = [];
      for (const com of parameter.communities) {
        if (com.district_id !== null) districts.push(com.district_id);
        else communities.push(com.community_id);
      }
      if (communities.length > 0)
        requestParams.communities = communities.join(",");
      if (districts.length > 0) requestParams.districts = districts.join(",");
    }
    if (parameter.rubric) requestParams.rubrics = parameter.rubric.rubric_id;
    if (parameter.addressLocation)
      requestParams.addressLocation = parameter.addressLocation.address_id;
    if (parameter.addressPromoter)
      requestParams.addressPromoter = parameter.addressPromoter.address_id;

    axios
      .get(FrontendSettings.host + "api/v1/articlesRecording", {
        params: requestParams,
      })
      .then((response) => {
        const promises: Promise<void>[] = [];

        const events: RecordingArticle[] = [];
        for (const data of response.data.data.results) {
          const promise = RecordingArticle.fromServer(data)
            .then((article) => {
              events.push(article);
            })
            .catch((e) => {
              FrontendLogger.error({
                message:
                  "Failed to parse RecordingArticle-object. Ignoring it.",
                data: e,
                scope: "api",
              });
            });
          promises.push(promise);
        }

        Promise.all(promises).then(() => {
          resolve({
            recordingArticles: events,
            pagesCountOverall: response.data.data.pages_count_overall,
            resultsCountOverall: response.data.data.results_count_overall,
            totalPageNumber: response.data.data.pages_count_overall,
          });
        });
      })
      .catch((e) => {
        reject(e);
      });
  });
}

export type GetRecordingArticlesReturnType = {
  recordingArticles: RecordingArticle[];
  pagesCountOverall: number;
  resultsCountOverall: number;
  totalPageNumber: number;
};

/**
 * Tries to create the given {@link RecordingArticle} via the api.
 * @param article
 * @return RecordingEvent the created {@link RecordingArticle}
 */
export function createRecordingArticle(
  article: RecordingArticle
): Promise<RecordingArticle> {
  return new Promise<RecordingArticle>((resolve, reject) => {
    axios
      .post(FrontendSettings.host + "api/v1/articlesRecording", article)
      .then((response) => {
        RecordingArticle.fromServer(response.data.data)
          .then((article) => {
            resolve(article);
          })
          .catch((e) => {
            FrontendLogger.error({
              message:
                "Failed to parse RecordingArticle-object after creation.",
              data: e,
              scope: "api",
            });
            reject(e);
          });
      })
      .catch((e) => {
        reject(e);
      });
  });
}

/**
 * Tries to update the given {@link RecordingArticle} via the api.
 * @param article
 * @return RecordingEvent the updated {@link RecordingArticle}
 */
export function updateRecordingArticle(
  article: RecordingArticle
): Promise<RecordingArticle> {
  return new Promise<RecordingArticle>((resolve, reject) => {
    axios
      .put(
        FrontendSettings.host +
          "api/v1/articlesRecording/" +
          article.article_id,
        article
      )
      .then((response) => {
        RecordingArticle.fromServer(response.data.data)
          .then((article) => {
            resolve(article);
          })
          .catch((e) => {
            FrontendLogger.error({
              message: "Failed to parse RecordingArticle-object after update.",
              data: e,
              scope: "api",
            });
            reject(e);
          });
      })
      .catch((e) => {
        reject(e);
      });
  });
}

/**
 *
 * @param event_id
 */
export function deleteRecordingArticle(article_id: number): Promise<void> {
  return new Promise<void>((resolve, reject) => {
    axios
      .delete(FrontendSettings.host + "api/v1/articlesRecording/" + article_id)
      .then(() => {
        try {
          resolve();
        } catch (e) {
          FrontendLogger.error({
            message: "Failed to parse RecordingArticle-object after delete.",
            data: e,
            scope: "api",
          });
          reject(e);
        }
      })
      .catch((e) => {
        reject(e);
      });
  });
}

/**
 * Tries to get a StringValueList from the api server.
 * This means, that the key of the items is a string.
 *
 * @return Returns the valueList of a specific type on resolve and an error on reject, if something failed. Could be an HTTP-Error or TypeError.
 */
export function getStringValueList(parameter: {
  type: "event_priority" | "person_roles";
}): Promise<StringValueListItem[]> {
  return new Promise<StringValueListItem[]>((resolve, reject) => {
    axios
      .get(FrontendSettings.host + "api/v1/valueLists", {
        params: parameter,
      })
      .then((response) => {
        const list: StringValueListItem[] = [];
        response.data.data.results.forEach(
          (data: ServerValueListItemDataType) => {
            try {
              list.push(new StringValueListItem(data));
            } catch (e) {
              FrontendLogger.error({
                message: "Failed to parse StringValueListItem-Object.",
                data: e,
                scope: "api",
              });
            }
          }
        );
        resolve(list);
      })
      .catch((e) => {
        reject(e);
      });
  });
}

/**
 * Tries to get a NumberValueList from the api server.
 * This means, that the key of the items has to be a number.
 *
 * @return Returns the valueList of a specific type on resolve and an error on reject, if something failed. Could be an HTTP-Error or TypeError.
 */
export function getNumberValueList(parameter: {
  type: "valueSet_event_publiclHoliday" | "valueSet_event_schoolHoliday";
}): Promise<NumberValueListItem[]> {
  return new Promise<NumberValueListItem[]>((resolve, reject) => {
    axios
      .get(FrontendSettings.host + "api/v1/valueLists", {
        params: parameter,
      })
      .then((response) => {
        const list: NumberValueListItem[] = [];
        response.data.data.results.forEach(
          (data: ServerValueListItemDataType) => {
            try {
              list.push(new NumberValueListItem(data));
            } catch (e) {
              FrontendLogger.error({
                message: "Failed to parse NumberValueListItem-Object.",
                data: e,
                scope: "api",
              });
            }
          }
        );
        resolve(list);
      })
      .catch((e) => {
        reject(e);
      });
  });
}

/**
 * Type of the response from `login`-function.
 */
export type LoginResponseType = {
  token: string;
  conditions: boolean;
  expire_datetime: DateTime;
};

/**
 * Delivers login information to api server. Is going to return session
 * when login is validated.
 *
 * @return string Session Token
 */
export function login(parameter: {
  username: string;
  password: string;
}): Promise<LoginResponseType> {
  return new Promise<LoginResponseType>((resolve, reject) => {
    axios
      .post(FrontendSettings.host + "api/v1/login/", parameter)
      .then((response) => {
        if (
          typeof response.data.data["jwt_token"] !== "string" ||
          response.data.data["jwt_token"].trim() === ""
        ) {
          reject(
            new Error("The server did not respond with an authorization token.")
          );
          return;
        }

        const expireDatetime = parseTimestamp(
          response.data.data["expire_datetime"] ?? ""
        );
        if (!expireDatetime) {
          reject(
            new Error("The server did not respond with an expire datetime.")
          );
          return;
        }

        resolve({
          token: response.data.data["jwt_token"],
          conditions: response.data.data["conditions"],
          expire_datetime: expireDatetime,
        });
      })
      .catch((e) => {
        reject(e);
      });
  });
}

/**
 * The response when executing Report(s).
 */
export type ReportExecutionResponse = {
  /**
   * List of executed reports
   */
  results: {
    report_id: number;
    report_name: string;
    output: string;
  }[];
};

/**
 * Tries to execute Report(s) with given ids.
 *
 * @returns
 */
export function executeReport(
  parameter: { event_ids: number[] } | { article_ids: number[] }
): Promise<ReportExecutionResponse> {
  const requestParams: Record<string, unknown> = Object.assign({}, parameter);

  if (requestParams.event_ids) {
    requestParams.event_ids = (requestParams.event_ids as number[]).join(",");
  }
  if (requestParams.article_ids) {
    requestParams.article_ids = (requestParams.article_ids as number[]).join(
      ","
    );
  }

  return new Promise<ReportExecutionResponse>((resolve, reject) => {
    axios
      .get(FrontendSettings.host + "api/v1/reporting/", {
        params: requestParams,
      })
      .then((response) => {
        try {
          resolve(response.data.data);
        } catch (e) {
          FrontendLogger.error({
            message: "Failed to parse Report-execution response-object.",
            data: e,
            scope: "api",
          });
          reject(e);
        }
      })
      .catch((e) => {
        reject(e);
      });
  });
}

/**
 * Type of the response from `signup`-function.
 */
export type SignupResponseType = {
  emailVerification: boolean;
};

/**
 * Delivers signup information to api Server.
 * If all data is valid and person is created successfully, returns if an emailVerification is necessary from api-settings.
 */
export function signup(signupData: SignupData): Promise<SignupResponseType> {
  // Convert SignupDate into the required form for api
  const parameter: Partial<SignupData> & {
    organizationId?: number;
    redirect_url?: string;
  } = Object.assign({}, signupData);

  // Replace organization Address object with the id
  if (parameter.organization) {
    parameter.organizationId = parameter.organization.address_id;
    delete parameter.organization;
  }

  // Add redirect URL
  if (!parameter.redirect_url) {
    parameter.redirect_url =
      window.location.protocol +
      "//" +
      window.location.host +
      FrontendSettings.embedPath;
  }

  return new Promise<SignupResponseType>((resolve, reject) => {
    axios
      .post(FrontendSettings.host + "api/v1/signup/", parameter)
      .then((response) => {
        resolve({
          emailVerification: response.data.data["emailVerification"],
        });
      })
      .catch((e) => {
        reject(e);
      });
  });
}

/**
 * Tries to confirm an e-mail with given {@link confirmation_token}
 */
export function confirmEMail(confirmation_token: string): Promise<void> {
  return new Promise<void>((resolve, reject) => {
    axios
      .put(FrontendSettings.host + "api/v1/confirmMail/", {
        confirmation_token,
      })
      .then(() => {
        resolve();
      })
      .catch((e) => {
        reject(e);
      });
  });
}

/**
 * Tries to request a new e-mail confirmation link to be sent to the persons email.
 */
export function requestConfirmEMail(username: string): Promise<void> {
  return new Promise<void>((resolve, reject) => {
    axios
      .post(FrontendSettings.host + "api/v1/confirmMail/", {
        redirect_url:
          window.location.protocol +
          "//" +
          window.location.host +
          FrontendSettings.embedPath,
        username,
      })
      .then(() => {
        resolve();
      })
      .catch((e) => {
        reject(e);
      });
  });
}

/**
 * Requests a password reset from the server.
 */
export function requestPasswordReset(username: string): Promise<void> {
  // Get the redirect-url
  const redirect_url =
    window.location.protocol +
    "//" +
    window.location.host +
    FrontendSettings.embedPath;

  return new Promise<void>((resolve, reject) => {
    axios
      .post(FrontendSettings.host + "api/v1/passwordReset/", {
        username,
        redirect_url,
      })
      .then(() => {
        resolve();
      })
      .catch((e) => {
        reject(e);
      });
  });
}

/**
 * Tries to reset the password of the user.
 */
export function changePassword(
  new_password: string | null = null,
  reset_token: string
): Promise<void> {
  return new Promise<void>((resolve, reject) => {
    axios
      .put(FrontendSettings.host + "api/v1/passwordReset/", {
        new_password,
        reset_token,
      })
      .then(() => {
        resolve();
      })
      .catch((e) => {
        reject(e);
      });
  });
}

/**
 * Type of the response from `changePersonData`-function.
 * @property string savedType   depends on changes saved, used for confirmation message after saving MyProfile
 */
export type changePersonResponseType = {
  savedType: string;
};

/**
 * Tries to change PersonData of active User
 */
export function changePersonData(
  parameter: Person,
  type?: "agreements" | "profile"
): Promise<changePersonResponseType> {
  return new Promise<changePersonResponseType>((resolve, reject) => {
    axios
      .put(FrontendSettings.host + "api/v1/persons/", {
        parameter,
        type,
      })
      .then((response) => {
        resolve({
          savedType: response.data.data["savedType"],
        });
      })
      .catch((e) => {
        reject(e);
      });
  });
}

/**
 * Tries to get a specific Person from the api server.
 *
 * @returns the Person on resolve and an error on reject, if something failed. Could be an HTTP-Error or TypeError.
 */
export function getPersonData(): Promise<Person> {
  return new Promise<Person>((resolve, reject) => {
    axios
      .get(FrontendSettings.host + "api/v1/persons/")
      .then((response) => {
        try {
          resolve(Person.fromServer(response.data.data));
        } catch (e) {
          FrontendLogger.error({
            message: "Failed to parse Person-object.",
            data: e,
            scope: "api",
          });
          reject(e);
        }
      })
      .catch((e) => {
        reject(e);
      });
  });
}
