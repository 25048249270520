
import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import QuokkaDatePicker from "@/components/quokka_layout/ui/QuokkaDatePicker.vue";
import EventDate from "@/shared/data/event_date";
import RecordingEvent from "@/shared/data/recording_event";
import FrontendSettings from "@/frontend/settings/settings";
import axios from "axios";
import FrontendLogger from "@/frontend/lib/logger";
@Component({
  components: {
    QuokkaDatePicker,
  },
})
export default class QuokkaEventRecordingAppointmentsPublicationDates extends Vue {
  /**
   * Watcher to update chosen weekdays in recordingEvent
   * keyID is an ID to find the weekdays in Array weekDays
   */
  @Watch("selectedWeekdays")
  updateRecordingEventSelectPublicationDays(): void {
    this.recordingEvent.publication_weekdays = [];
    for (const keyID in this.selectedWeekdays) {
      this.recordingEvent.publication_weekdays.push(this.weekDays[keyID].id);
    }
  }

  @Prop({ required: true }) recordingEvent!: RecordingEvent;
  publicationDaysConfig: [{ key: string; value: number }] = [
    { key: "keine Tage", value: 0 },
  ];
  weekDays = [
    { id: "1", name: "Montag" },
    { id: "2", name: "Dienstag" },
    { id: "3", name: "Mittwoch" },
    { id: "4", name: "Donnerstag" },
    { id: "5", name: "Freitag" },
    { id: "6", name: "Samstag" },
    { id: "7", name: "Sonntag" },
  ];
  selectedWeekdays: { id: string; name: string }[] = [];

  mounted(): void {
    this.publicationDaysConfig = this.getDaysForPublicationDatesConfig;
    for (const selectedPublicationWeekdayFromRecordingEvent of this
      .recordingEvent.publication_weekdays) {
      this.selectedWeekdays.push(
        this.weekDays[Number(selectedPublicationWeekdayFromRecordingEvent) - 1]
      );
    }
    return;
  }

  addEventDate(): void {
    this.recordingEvent.publication_dates.push(new EventDate());
  }
  removeEventDate(index: number): void {
    this.recordingEvent.publication_dates.splice(index, 1);
  }
  get getDaysForPublicationDatesConfig(): [{ key: string; value: number }] {
    let publicationDayConfig: [{ key: string; value: number }] = [
      { key: "", value: 0 },
    ];
    axios
      .get(FrontendSettings.host + "api/v1/valueLists/", {
        params: { type: "event_recording_publication_days" },
      })
      .then((responseData) => {
        for (let result of responseData.data.data.results) {
          if (result.id == 1) {
            publicationDayConfig.push({
              key: result.id + " Tag",
              value: Number(result.id),
            });
          } else {
            publicationDayConfig.push({
              key: result.id + " Tage",
              value: Number(result.id),
            });
          }
        }
      })
      .catch((e) => {
        FrontendLogger.error({
          message:
            "Failed to get available days for selection of publication days before date.",
          data: e,
        });
      });

    return publicationDayConfig;
  }
}
