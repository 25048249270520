import {
  VuetifyThemeItem,
  VuetifyThemeVariant,
} from "vuetify/types/services/theme";
import Layout from "../../frontend/lib/layouts";
import { EventLinkUsage } from "./event_link";
import { FormDefinition } from "./form";

/**
 * Base settings class holding the data for the client-settings.
 *
 * Does not contain api-credentials or node-side-settings.
 */
export abstract class Settings {
  public static layout = Layout.Quokka;
  public static theme: ThemeSettings = {
    fontFamily: null,
    isDark: false,
    light: {},
    dark: {},
  };

  public static router: RouterSettings = {
    scrollBehaviourOffset: 0,
  };

  public static applicationSettings: ApplicationSettings = {
    footerContact: null,
    activateEventsRecording: true,
    activateAdverts: false,
    activateArticles: false,
  };

  public static eventList: EventListSettings = {
    resultsPerPage: 24,
  };

  /**
   * Settings for the `EventDetailsView`.
   */
  public static eventDetails: EventDetailsSettings = {
    shortdesc1: {
      visible: true,
    },
    shortdesc2: {
      visible: true,
    },
    shortdesc3: {
      visible: true,
    },
    longdesc1: {
      visible: true,
    },
    longdesc2: {
      visible: true,
    },
    longdesc3: {
      visible: true,
    },
  };

  /**
   * Settings for the `EventRecordingView`.
   */
  public static eventRecording: EventRecordingSettings = {
    /**
     * Whether to show the report preview in event-recording or not.
     */
    showReportPreview: false,

    /**
     * Whether to show the media tab in event-recording or not
     */
    showMediaTab: false,

    /**
     * Whether to show the ticket agency tab in event-recording or not
     */
    showTicketTab: false,

    /** Whether to show the free field tab or not */
    showFreeFields: false,

    /** Label for the free fields tab */
    labelFreeFields: null,

    /** Title for the event recording view */
    titleRecordingEvents: "Veranstaltung erfassen",

    /** Title for the my-events view */
    titleMyEvents: "Meine Veranstaltungen",

    /**
     * An optional info text to display in the texts-tab.
     *
     * This text supports HTML.
     */
    textsInfo: null,
    /**
     * An optional info text to display in the others-tab.
     *
     * This text supports HTML.
     */
    othersInfo: null,
    /**
     * An optional info text to display in the media-tab.
     *
     * This text supports HTML.
     */
    mediaInfo: null,
    /**
     * An optional info text to display in the general-tab.
     *
     * This text supports HTML.
     */
    generalInfo: null,
    /**
     * An optional info text to display in the appointments-tab.
     *
     * This text supports HTML.
     */
    appointmentsInfo: null,
    /**
     * An optional info text to display in the appointmentsSeries-tab.
     *
     * This text supports HTML.
     */
    appointmentsSeriesInfo: null,
    /**
     * An optional info text to display in the appointmentsFree-tab.
     *
     * This text supports HTML.
     */
    appointmentsFreeInfo: null,

    /**
     * An optional info text to display in the ticketAgency-tab
     * This text supports HTML
     */
    ticketsInfo: null,

    /**
     * Settings for the additional fields in event recording.
     */
    additionalFieldsFormDefinition: null,
  };

  /**
   * Settings for the `ArticleRecordingView`.
   */
  public static articleRecording: ArticleRecordingSettings = {
    /**
     * Whether to show the report preview in event-recording or not.
     */
    showReportPreview: false,

    /**
     * Whether to show the Media Tab in article-recording or not.
     */
    showMediaTab: false,

    /** Title for the articles-recording view */
    titleRecordingArticles: "Artikel erfassen",

    /** Title for the my-articles view */
    titleMyArticles: "Meine Artikel",

    /**
     * An optional info text to display in the texts-tab.
     *
     * This text supports HTML.
     */
    textsInfo: null,
    mediaInfo: null,
    generalInfo: null,
    appointmentsInfo: null,
    appointmentsSeriesInfo: null,
    appointmentsFreeInfo: null,
  };

  public static authenticatedArea: AuthenticatedAreaSettings = {
    labelLogin: "Login",
    labelOfficialAddress: "Geschäftsadresse",
    labelPrivateAddress: "Private Adresse",
    showLinksInCardFooters: true,
    showLoginSignupButton: true,
    requiredConditions: true,
    requiredPrivacyPolicy: true,
    registrationSuccessMessage: null,
    mainInstructionMessage: null,
    titleLandingPage: "Hallo, ",
    appendName: true,
    titleMyProfile: "Mein Profil",
    missingPersonDataMessage: null,

    loginInfo: null,
    officialAddressInfo: null,
    privateAddressInfo: null,

    profileFieldSettings: {
      login: {
        max_length: null,
        min_length: null,
        regex: null,
        info: null,
      },
      official_address: {
        required: true,
        organisation: {
          label: null,
          info: null,
          regex: null,
          required: false,
          min: 0,
          max: 0,
        },
        street: {
          label: null,
          info: null,
          regex: null,
          required: false,
        },
        plz: {
          label: null,
          info: null,
          regex: null,
          required: false,
        },
        community: {
          label: null,
          info: null,
          regex: null,
          required: false,
        },
        phone: {
          label: null,
          info: null,
          regex: null,
          required: false,
        },
        mobile: {
          label: null,
          info: null,
          regex: null,
          required: false,
        },
      },
      private_address: {
        required: false,
        street: {
          label: null,
          info: null,
          regex: null,
          required: false,
        },
        plz: {
          label: null,
          info: null,
          regex: null,
          required: false,
        },
        community: {
          label: null,
          info: null,
          regex: null,
          required: false,
        },
        phone: {
          label: null,
          info: null,
          regex: null,
          required: false,
        },
        mobile: {
          label: null,
          info: null,
          regex: null,
          required: false,
        },
      },
    },

    enableOfficialAddress: true,
    enablePrivateAddress: false,
  };

  public static links: LinksSettings = {
    conditions: "",
    privacyPolicy: "",
    imprint: "",
  };

  public static advertSettings: AdvertSettings = {
    overview_event_text: true,
    titleMyAdverts: "Meine Werbung",
    titleRecordingAdverts: "Werbung buchen",
    /** infotext for medium selection */
    titleMedium: "Wo soll Ihre Werbung angezeigt werden?",
    medium_info_text: "",
    /** infotext for channel selection */
    titleChannel: "Wo soll Ihre Werbung angezeigt werden?",
    channel_info_text: "",
    /** infotext for slot selection */
    titleSlot: "Wie soll Ihre Werbung aussehen?",
    slot_info_text: "",
    /** infotext for date selection */
    titleWhen: "Wann soll Ihre Werbung zu sehen sein?",
    when_info_text: "",
    /** infotext for the event selection */
    titleEventSelection: "Soll eine Veranstaltung verknüpft werden?",
    event_selection_info_text: "",
    /** infotext for existing event */
    titleEventExisting: "Bitte wählen Sie eine existierende Veranstaltung aus",
    event_existing_info_text: "",
    /** infotext for new event */
    titleEventNew: "Neue Veranstaltung für die Buchung erfassen",
    event_new_info_text: "",
    /** infotext for content */
    titleContent: "Was soll in Ihrer Werbung zu sehen sein",
    content_info_text: "",
    /** infotext for billing address */
    titleBillingAddress: "Ihre Rechnungsadresse",
    billing_address_info_text: "",
    /** infotext for overview */
    titleOverview: "Ihre Werbung in der Übersicht",
    overview_info_text: "",
    /** infotext for landingpage */
    titleSuccess: "Ihre Werbung ist abgeschlossen",
    landing_page_info_text: "",
    /** infotext for success overview */
    titleShowAdverts: "Werbung einsehen",
    success_overview_info_text: "",
  };

  public static languageSettings: LanguageSettings = {
    lbl_advert: "Werbung",
    lbl_street: "Straße",
  };

  public static fieldSettings: FormFields = {
    recording: {
      title: {
        maxLength: null,
      },
      shortdesc1: {
        maxLength: null,
        label: null,
        visible: true,
      },
      shortdesc_quill_options: {
        active: false,
        modules: {
          toolbar: [["none"]],
        },
        readonly: false,
      },
      shortdesc2: {
        maxLength: null,
        label: null,
        visible: false,
      },
      shortdesc2_quill_options: {
        active: false,
        modules: {
          toolbar: [["none"]],
        },
        readonly: false,
      },
      shortdesc3: {
        maxLength: null,
        label: null,
        visible: false,
      },
      shortdesc3_quill_options: {
        active: false,
        modules: {
          toolbar: [["none"]],
        },
        readonly: false,
      },
      longdesc1: {
        maxLength: null,
        label: null,
        visible: true,
      },
      longdesc_quill_options: {
        active: false,
        modules: {
          toolbar: [["none"]],
        },
        readonly: false,
      },
      longdesc2: {
        maxLength: null,
        label: null,
        visible: false,
      },
      longdesc2_quill_options: {
        active: false,
        modules: {
          toolbar: [["none"]],
        },
        readonly: false,
      },
      longdesc3: {
        maxLength: null,
        label: null,
        visible: false,
      },
      longdesc3_quill_options: {
        active: false,
        modules: {
          toolbar: [["none"]],
        },
        readonly: false,
      },
      addRubrics: {
        rubricsMin: 0,
        rubricsMax: 0,
      },
      links: {
        ticket: {
          maxAmount: 0,
        },
        location: {
          maxAmount: 0,
        },
        promoter: {
          maxAmount: 0,
        },
        event: {
          maxAmount: 0,
        },
        artist: {
          maxAmount: 0,
        },
      },
      appointments: {
        additionalInfo: {
          visible: true,
        },
      },
      priority: {
        visible: true,
      },
      tags: {
        visible: true,
      },
      publicationDates: {
        maxAmountDatesBeforeEvent: 0,
        usePublicationDates: false,
      },
    },
    auth: {
      organization: {
        type: "text",
      },
    },
  };

  /**
   * If set, this host will be used when getting `host`.
   */
  public static overrideHost: string | null = null;

  /**
   * Tries to load the settings from the given data.
   * If the settings file has already been loaded, the promise is resolved instantly.
   *
   * TODO: We might have to merge the objects deeper than just the first layer at some point.
   */
  static loadFrom(data: Record<string, unknown>): void {
    if (Object.keys(data).length > 0) {
      Object.assign(this, data);
    }
  }
}

/**
 * Settings for the Theme.
 */
type ThemeSettings = {
  fontFamily: string | null;
  isDark: boolean;
  light: Partial<VuetifyThemeVariant & { background: VuetifyThemeItem }>;
  dark: Partial<VuetifyThemeVariant & { background: VuetifyThemeItem }>;
};

/**
 * Settings for the router.
 */
type RouterSettings = {
  /**
   * The offset to keep on the top when a route is switched.
   * Required, if the application is embedded and a header would cover the content.
   *
   * This is used, because the router always scrolls to the top of the view.
   */
  scrollBehaviourOffset: number;
};

/**
 * General Settings for the application.
 */
type ApplicationSettings = {
  /**
   * HTML to show in the applications' footer as contact information.
   */
  footerContact: string | null;

  /**
   * Whether events recording is activated or not.
   */
  activateEventsRecording: boolean;

  /**
   * Whether adverts are activated or not.
   */
  activateAdverts: boolean;

  /**
   * Whether article are activated or not.
   */
  activateArticles: boolean;
};

/**
 * Settings for the EventList.
 */
type EventListSettings = {
  resultsPerPage: number;
};

/**
 * Settings for the `EventDetailsView`.
 */
type EventDetailsSettings = {
  /**
   * Settings for the output of the `shortdesc1`.
   */
  shortdesc1: FormFieldSettingsVisibility;

  /**
   * Settings for the output of the `shortdesc2`.
   */
  shortdesc2: FormFieldSettingsVisibility;

  /**
   * Settings for the output of the `shortdesc3`.
   */
  shortdesc3: FormFieldSettingsVisibility;

  /**
   * Settings for the output of the `longdesc1`.
   */
  longdesc1: FormFieldSettingsVisibility;

  /**
   * Settings for the output of the `longdesc2`.
   */
  longdesc2: FormFieldSettingsVisibility;

  /**
   * Settings for the output of the `longdesc3`.
   */
  longdesc3: FormFieldSettingsVisibility;
};

/**
 * Settings for the `EventRecordingView`.
 */
type EventRecordingSettings = {
  /**
   * Whether to show the report preview in event-recording or not.
   */
  showReportPreview: boolean;

  /**
   * Whether to show the media tab in event-recording or not.
   */
  showMediaTab: boolean;

  /**
   * Whether to show the ticket agency tab in event-recording or not.
   */
  showTicketTab: boolean;

  /** Whether to show the free field tab or not */
  showFreeFields: boolean;

  /** Title for the event recording view */
  titleRecordingEvents: string;

  /** Title for the my-events view */
  titleMyEvents: string;

  /** Label for the free fields tab */
  labelFreeFields: string | null;

  /**
   * An optional info text to display in the texts-tab.
   *
   * This text supports HTML.
   */
  textsInfo: string | null;
  othersInfo: string | null;
  mediaInfo: string | null;
  generalInfo: string | null;
  appointmentsInfo: string | null;
  appointmentsSeriesInfo: string | null;
  appointmentsFreeInfo: string | null;
  ticketsInfo: string | null;

  /**
   * Settings for the additional fields in event recording, if null no additional field is available.
   */
  additionalFieldsFormDefinition: FormDefinition | null;
};

/**
 * Settings for the `ArticleRecordingView`.
 */
type ArticleRecordingSettings = {
  /**
   * Whether to show the report preview in article-recording or not.
   */
  showReportPreview: boolean;

  /**
   * Whether to show media tab in article-recording or not
   */
  showMediaTab: boolean;

  /** Title for the articles-recording view */
  titleRecordingArticles: string;

  /** Title for the my-articles view */
  titleMyArticles: string;

  /**
   * An optional info text to display in the texts-tab.
   *
   * This text supports HTML.
   */
  textsInfo: string | null;
  mediaInfo: string | null;
  generalInfo: string | null;
  appointmentsInfo: string | null;
  appointmentsSeriesInfo: string | null;
  appointmentsFreeInfo: string | null;
};

/**
 * Settings for the authenticated area.
 *
 * Contains Login / Signup and the views like recording etc.
 */
export type AuthenticatedAreaSettings = {
  /**
   * The label of the button that leads to the login/signup views.
   */
  labelLogin: string;

  /**
   * Whether to show the links for conditions, privacy policy and imprint in the footer of
   * the card for login and signup.
   *
   * This will not affect the links used in the checkbox labels in Signup-View.
   */
  showLinksInCardFooters: boolean;

  /**
   * Whether to show the button that leads to the Login/Signup View should be shown or not.
   */
  showLoginSignupButton: boolean;

  /** Whether agreeing to Terms and Conditions is required */
  requiredConditions: boolean;

  /** Whether agreeing to Privacy Policy is required */
  requiredPrivacyPolicy: boolean;

  registrationSuccessMessage: string | null;

  /** Title for the Landing Page and if th person name should be appended */
  titleLandingPage: string;
  appendName: true;

  /** Title to display in my-profile */
  titleMyProfile: string;

  /** Message displayed at the main admin view */
  mainInstructionMessage: string | null;
  missingPersonDataMessage: string | null;

  /** Additional Labels and Information Texts for Signup */
  labelOfficialAddress: string;
  labelPrivateAddress: string;

  loginInfo: string | null;
  officialAddressInfo: string | null;
  privateAddressInfo: string | null;

  profileFieldSettings: ProfileFieldSettings;

  enableOfficialAddress: boolean;
  enablePrivateAddress: boolean;
};

/** Settings for profile and registration fields */
export type ProfileFieldSettings = {
  login: {
    max_length: number | null;
    min_length: number | null;
    regex: string | null;
    info: string | null;
  };
  official_address: {
    required: boolean;
    organisation: {
      label: string | null;
      info: string | null;
      regex: string | null;
      required: boolean;
      min: number;
      max: number;
    };
    street: {
      label: string | null;
      info: string | null;
      regex: string | null;
      required: boolean;
    };
    plz: {
      label: string | null;
      info: string | null;
      regex: string | null;
      required: boolean;
    };
    community: {
      label: string | null;
      info: string | null;
      regex: string | null;
      required: boolean;
    };
    phone: {
      label: string | null;
      info: string | null;
      regex: string | null;
      required: boolean;
    };
    mobile: {
      label: string | null;
      info: string | null;
      regex: string | null;
      required: boolean;
    };
  };
  private_address: {
    required: boolean;
    street: {
      label: string | null;
      info: string | null;
      regex: string | null;
      required: boolean;
    };
    plz: {
      label: string | null;
      info: string | null;
      regex: string | null;
      required: boolean;
    };
    community: {
      label: string | null;
      info: string | null;
      regex: string | null;
      required: boolean;
    };
    phone: {
      label: string | null;
      info: string | null;
      regex: string | null;
      required: boolean;
    };
    mobile: {
      label: string | null;
      info: string | null;
      regex: string | null;
      required: boolean;
    };
  };
};

/**
 * URLs for various links.
 */
type LinksSettings = {
  conditions: string;
  privacyPolicy: string;
  imprint: string;
};

/**
 * Settings for Wysiwyg Editor in EventRecording
 */
type QuillSettings = {
  active: boolean;
  modules: {
    toolbar: ("bold" | "italic" | string)[][];
  };
  readonly: boolean;
};

/**
 * Settings for Advert values.
 */
type AdvertSettings = {
  /**
   * Whether the texts of a linked event should be displayed in the advert-overview.
   */
  overview_event_text: boolean;
  titleMyAdverts: string;
  titleRecordingAdverts: string;
  titleMedium: string;
  medium_info_text: string;
  titleChannel: string;
  channel_info_text: string;
  titleSlot: string;
  slot_info_text: string;
  titleWhen: string;
  when_info_text: string;
  titleEventSelection: string;
  event_selection_info_text: string;
  titleEventExisting: string;
  event_existing_info_text: string;
  titleEventNew: string;
  event_new_info_text: string;
  titleContent: string;
  content_info_text: string;
  titleBillingAddress: string;
  billing_address_info_text: string;
  titleOverview: string;
  overview_info_text: string;
  titleSuccess: string;
  landing_page_info_text: string;
  titleShowAdverts: string;
  success_overview_info_text: string;
};

/**
 * Settings for language values.
 */
type LanguageSettings = {
  /**
   * "Advert" - "Werbung"
   */
  lbl_advert: string;

  /**
   * "Street" - "Straße"
   */
  lbl_street: string;
};

/**
 * Contains all available settings for various form fields of the application.
 */
type FormFields = {
  /**
   * Form fields in the recording form.
   */
  recording: {
    title: FormFieldTextFieldSettings;

    /**
     * Settings for the `shortdesc1` field.
     */
    shortdesc1: FormFieldTextFieldSettings &
      FormFieldSettingsLabel &
      FormFieldSettingsVisibility;
    shortdesc_quill_options: QuillSettings;

    /**
     * Settings for the `shortdesc2` field.
     */
    shortdesc2: FormFieldTextFieldSettings &
      FormFieldSettingsLabel &
      FormFieldSettingsVisibility;
    shortdesc2_quill_options: QuillSettings;

    /**
     * Settings for the `shortdesc3` field.
     */
    shortdesc3: FormFieldTextFieldSettings &
      FormFieldSettingsLabel &
      FormFieldSettingsVisibility;
    shortdesc3_quill_options: QuillSettings;

    /**
     * Settings for the `longdesc1` field.
     */
    longdesc1: FormFieldTextFieldSettings &
      FormFieldSettingsLabel &
      FormFieldSettingsVisibility;
    longdesc_quill_options: QuillSettings;

    /**
     * Settings for the `longdesc2` field.
     */
    longdesc2: FormFieldTextFieldSettings &
      FormFieldSettingsLabel &
      FormFieldSettingsVisibility;
    longdesc2_quill_options: QuillSettings;

    /**
     * Settings for the `longdesc3` field.
     */
    longdesc3: FormFieldTextFieldSettings &
      FormFieldSettingsLabel &
      FormFieldSettingsVisibility;
    longdesc3_quill_options: QuillSettings;

    addRubrics: { [key: string]: number };

    /**
     * Settings for all available link-fields.
     *
     * The keys in this object has to be the same as the available {@link EventLinkUsage}s.
     */
    links: { [key in EventLinkUsage]: FormFieldEventLinkSettings };

    /**
     * Settings for appointment fields
     */
    appointments: {
      /**
       * Settings for the "additional info" field of an appointment.
       */
      additionalInfo: FormFieldSettingsVisibility;
    };

    priority: FormFieldSettingsVisibility;
    tags: FormFieldSettingsVisibility;
    publicationDates: FormFieldPublicationDatesSettings;
  };

  /**
   * Form fields in the auth forms like Signup or Login.
   */
  auth: {
    organization: {
      type: "text" | "autocomplete";
    };
  };
};

/**
 * Settings for form fields to define the visibility of it.
 */
type FormFieldSettingsVisibility = {
  /**
   * Whether this field should be visible or not.
   */
  visible: boolean;
};

/**
 * Settings for form fields to define the label to display.
 */
type FormFieldSettingsLabel = {
  /**
   * The label for this form field.
   */
  label: string | null;
};

// Special Settings for various field types

/**
 * Settings for fields of type text.
 */
type FormFieldTextFieldSettings = {
  /**
   * The maximum length of text that can be entered.
   *
   * If null, no limit is set.
   */
  maxLength: number | null;
};

// Special Settings for various fields

type FormFieldEventLinkSettings = {
  maxAmount: number;
};

type FormFieldPublicationDatesSettings = {
  maxAmountDatesBeforeEvent: number;
  usePublicationDates: boolean;
};
