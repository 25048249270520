import Address, { ServerAddressDataType } from "@/shared/data/address";
import Rubric, { ServerRubricDataType } from "@/shared/data/rubric";
import DataStruct from "@/shared/data/datastruct";
import Tag, { ServerTagDataType } from "@/shared/data/tag";
import Text, { ServerTextDataType } from "@/shared/data/text";
import {
  RecordingEventAppointments,
  RecordingEventAppointmentsFree,
  RecordingEventAppointmentsSeries,
  ServerRecordingEventAppointmentsDataType,
} from "@/shared/data/event_appointments";
import EventLink, { ServerEventLinkDataType } from "@/shared/data/event_link";
import {
  RecordingMedia,
  ServerRecordingMediaDataType,
} from "@/shared/data/media";
import RecordingEventValidationError, {
  RecordingEventValidationErrorReason,
} from "@/frontend/lib/exceptions/RecordingEventValidationError";
import EventDate from "@/shared/data/event_date";
import { DateTime } from "luxon";
import { formatDate, parseDate } from "@/shared/lib/datetime";
import TimeInfo from "@/shared/data/time_info";
import { ServerAdditionFieldValuesType } from "@/shared/data/recording_article";
import FrontendSettings from "@/frontend/settings/settings";
import { InputAdditionField } from "@/shared/data/addition_field";
import {
  MediaInformationData,
  ServerMediaInformationDataType,
} from "@/shared/data/media_information";

/**
 * The RecordingEvent Data-Class containing all properties for an Event for recording.
 */
export default class RecordingEvent extends DataStruct {
  readonly event_id: number;
  text: Text = new Text();
  add_field_data: {
    [key: string]: string | boolean | DateTime | number | null;
  } = {};

  // Appointments
  appointments: RecordingEventAppointments | null = null;

  // Address
  location: Address | null = null;
  promoter: Address | null = null;

  rubric: Rubric | null = null;
  priority = "";
  tags: Tag[] = [];
  links: EventLink[] = [];
  publication_dates: EventDate[] = [];
  days_publication_dates_before_event_date = 0;
  publication_weekdays: string[] = [];
  additionalImageInformation: MediaInformationData[] = [];

  ticket_agencies: string[] = [];

  /** The selected additional {@link Rubric}s */
  add_rubrics: Rubric[] | null = null;

  /**
   * List of `upload_key`s from the uploaded media.
   * Only set to add media. Will not be output by the server.
   */
  upload_media: string[] = [];

  /**
   * List of uploaded EventMedia assigned to the {@link RecordingEvent}.
   */
  uploaded_media: RecordingMedia[] = [];

  /**
   * Whether all added files are successfully uploaded.
   */
  fileUploadReady = true;

  /**
   * The count of removed, already saved files
   */
  countRemovedUploads = 0;

  /**
   * Creates a new instance of Event with given data.
   *
   * @param data The data to load
   * @throws TypeError if any property does not fit the type
   */
  constructor(data: { event_id: number }) {
    super();

    if (DataStruct.validateValue(data, "event_id", "number")) {
      this.event_id = data.event_id;
    } else {
      // We cannot initialize without an id
      throw new TypeError("Could not initialize Event. Invalid id.");
    }
  }

  /**
   * Creates a new RecordingEvent-object with the given data returned from the server.
   *
   * @param data
   */
  static fromServer(data: ServerRecordingEventDataType): RecordingEvent {
    const event = new RecordingEvent({
      event_id: data.event_id ?? 0,
    });

    // Load the data
    if (DataStruct.validateValue(data, "text", "object")) {
      event.text = Text.fromServer(<ServerTextDataType>data.text);
    }

    // Appointments
    if (DataStruct.validateValue(data, "appointments", "object")) {
      event.appointments = RecordingEventAppointments.fromServer(
        <ServerRecordingEventAppointmentsDataType>data.appointments
      );
    }

    if (DataStruct.validateValue(data, "location", "object")) {
      event.location = Address.fromServer(<ServerAddressDataType>data.location);
    }
    if (DataStruct.validateValue(data, "promoter", "object")) {
      event.promoter = Address.fromServer(<ServerAddressDataType>data.promoter);
    }

    if (DataStruct.validateValue(data, "rubric", "object")) {
      event.rubric = Rubric.fromServer(<ServerRubricDataType>data.rubric);
    }

    // Add rubrics
    if (Object.prototype.hasOwnProperty.call(data, "add_rubrics")) {
      data.add_rubrics?.forEach((item) => {
        if (event.add_rubrics === null) event.add_rubrics = [];

        event.add_rubrics.push(Rubric.fromServer(<ServerRubricDataType>item));
      });
    }

    if (DataStruct.validateValue(data, "priority", "string"))
      event.priority = <string>data.priority;

    if (Object.prototype.hasOwnProperty.call(data, "tags")) {
      event.tags = [];
      data.tags?.forEach((item) => event.tags.push(Tag.fromServer(item)));
    }
    if (Object.prototype.hasOwnProperty.call(data, "links")) {
      event.links = [];
      data.links?.forEach((item) =>
        event.links.push(EventLink.fromServer(item))
      );
    }

    if (Object.prototype.hasOwnProperty.call(data, "uploaded_media")) {
      event.uploaded_media = [];
      data.uploaded_media?.forEach((item) =>
        event.uploaded_media.push(RecordingMedia.fromServer(item))
      );
    }
    if (
      Object.prototype.hasOwnProperty.call(data, "additionalImageInformation")
    ) {
      event.additionalImageInformation = [];
      data.additionalImageInformation?.forEach((item) => {
        event.additionalImageInformation.push(
          MediaInformationData.fromServer(item)
        );
      });
    }

    if (data.ticket_agencies && data.ticket_agencies.length > 0) {
      event.ticket_agencies = data.ticket_agencies;
    }

    if (data.publication_dates && data.publication_dates?.length > 0) {
      for (const publicationDatesData of data.publication_dates) {
        const tempEventDate = new EventDate();
        tempEventDate.setDate(publicationDatesData, "yyyy-MM-dd");
        event.publication_dates.push(tempEventDate);
      }
    }

    if (data.days_publication_dates_before_event_date) {
      console.log("Tage:", data.days_publication_dates_before_event_date);
      event.days_publication_dates_before_event_date =
        data.days_publication_dates_before_event_date;
    }

    if (data.publication_weekdays) {
      event.publication_weekdays = data.publication_weekdays;
    }

    const eventDefinition =
      FrontendSettings.eventRecording.additionalFieldsFormDefinition;
    // Load and parse event free fields data
    if (DataStruct.validateValue(data, "add_field_data", "object")) {
      data.add_field_data.forEach((item) => {
        event.add_field_data[item.field_name] = item.field_value;
        //if input Field id a date parse String to date
        if (eventDefinition) {
          for (const formRow of eventDefinition) {
            for (const additionalField of formRow) {
              if (
                !additionalField.additionField ||
                !additionalField.additionField.type
              ) {
                continue;
              }
              if (
                item.field_name == additionalField.additionField.name &&
                additionalField.additionField.type == "input"
              ) {
                const inputField = <InputAdditionField>(
                  additionalField.additionField
                );
                if (inputField.input_type == "date") {
                  try {
                    const parsedDate = parseDate(
                      <string>item.field_value,
                      "yyyy-MM-dd"
                    );
                    if (parsedDate)
                      event.add_field_data[item.field_name] = parsedDate;
                  } catch (e) {
                    throw new TypeError(
                      "Invalid date-value '" +
                        item.field_value +
                        "' for article-field '" +
                        item.field_name +
                        "'."
                    );
                  }
                }
                break;
              }
            }
          }
        }
      });
    }
    return event;
  }

  /**
   * Creates a new RecordingEvent with an id of 0.
   */
  static create(): RecordingEvent {
    return new RecordingEvent({
      event_id: 0,
    });
  }

  /**
   * Prepares this {@link RecordingEvent} for save.
   *
   * - Orders the appointments, if possible.
   */
  prepareForSave(): void {
    if (this.appointments && this.appointments.type === "free") {
      const freeAppointments: RecordingEventAppointmentsFree = this
        .appointments as RecordingEventAppointmentsFree;
      freeAppointments.dates.sort((dateA, dateB) => {
        if (dateA.dateObject === null || dateB.dateObject === null) return 0;
        return dateA.dateObject.toMillis() - dateB.dateObject.toMillis();
      });
    }
  }

  /**
   * Validates this {@link RecordingEvent}.
   */
  validate(): boolean {
    const toDay = DateTime.now();

    if (!this.appointments) {
      throw new RecordingEventValidationError({
        message: "No appointments.",
        reason: RecordingEventValidationErrorReason.UNKNOWN,
      });
    }

    if (this.appointments.type === "free") {
      const appointmentsFree: RecordingEventAppointmentsFree = this
        .appointments as RecordingEventAppointmentsFree;

      const isDateValid =
        appointmentsFree.dates.filter((eventDate: EventDate) => {
          if (eventDate.dateObject) {
            if (toDay.toMillis() < eventDate.dateObject.toMillis()) {
              return true;
            }
          }
          return false;
        }).length > 0;

      if (!isDateValid) {
        throw new RecordingEventValidationError({
          message: "No valid date found in type free.",
          reason: RecordingEventValidationErrorReason.EXPIRED,
        });
      }
    } else if (this.appointments.type === "series") {
      const appointmentsSeries: RecordingEventAppointmentsSeries = this
        .appointments as RecordingEventAppointmentsSeries;

      let currentDate = appointmentsSeries.end_date;

      if (!currentDate) {
        throw new RecordingEventValidationError({
          message: "No event end date.",
          reason: RecordingEventValidationErrorReason.UNKNOWN,
        });
      }

      const weekDayTimeDefinition = [
        appointmentsSeries.appointmentsPerDay.monday,
        appointmentsSeries.appointmentsPerDay.tuesday,
        appointmentsSeries.appointmentsPerDay.wednesday,
        appointmentsSeries.appointmentsPerDay.thursday,
        appointmentsSeries.appointmentsPerDay.friday,
        appointmentsSeries.appointmentsPerDay.saturday,
        appointmentsSeries.appointmentsPerDay.sunday,
      ];

      let appointmentsValid = false;

      while (currentDate.toMillis() >= toDay.toMillis()) {
        const currentDay = parseInt(formatDate(currentDate, "c") ?? "");

        if (currentDay <= 0) {
          throw new RecordingEventValidationError({
            message: "No current day number.",
            reason: RecordingEventValidationErrorReason.UNKNOWN,
          });
        }

        if (
          weekDayTimeDefinition.filter(
            (timeInfo: TimeInfo[] | null, index) =>
              timeInfo && index + 1 <= currentDay
          ).length > 0
        ) {
          appointmentsValid = true;
          break;
        }

        currentDate = currentDate.minus({ day: 1 });
      }

      if (!appointmentsValid) {
        throw new RecordingEventValidationError({
          message: "No valid date found in type series.",
          reason: RecordingEventValidationErrorReason.EXPIRED,
        });
      }
    }

    if (!this.add_rubrics) {
      throw new RecordingEventValidationError({
        message: "No additional rubics.",
        reason: RecordingEventValidationErrorReason.UNKNOWN,
      });
    }

    return true;
  }
}

/**
 * The data structure as returned by the MSUevent-Server.
 */
export type ServerRecordingEventDataType = {
  event_id?: number;
  text: ServerTextDataType;
  appointments?: ServerRecordingEventAppointmentsDataType;
  priority?: string;
  location?: ServerAddressDataType;
  promoter?: ServerAddressDataType;
  rubric: ServerRubricDataType;
  add_rubrics: ServerRubricDataType[];
  tags?: ServerTagDataType[];
  links?: ServerEventLinkDataType[];
  add_field_data: ServerAdditionFieldValuesType[];
  uploaded_media?: ServerRecordingMediaDataType[];
  publication_dates?: [];
  days_publication_dates_before_event_date?: number;
  publication_weekdays?: string[];
  additionalImageInformation?: ServerMediaInformationDataType[];
  ticket_agencies?: string[];
};
